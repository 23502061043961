@charset "utf-8";
// Imports sass/scss files

@import "poole";
@import "hyde";
@import "highlight";

.drawer-open #_sidebar{
    box-shadow: 3px 3px 6px #956585 !important;
}
.demo-images{
    cursor: pointer;
}
article.post img{
    cursor: pointer;
}
article.post table thead{
    background-color: rgba(0, 136, 204, .7);
    color: #f0f0f0;
}
article.post table tbody tr:hover{
    background-color: rgba(0, 136, 204, .06);
}
code.highlighter-rouge{
    padding: 3px 6px;
    background-color: #f8f8f8;
    border: 1px solid #d1d1d9;
    margin: 3px;
    border-radius: 2px;
    font-size: 85%;
}
.hide{
    display: none;
}

// code {
//     padding: 2px 4px;
//     font-size: 90%;
//     color: #333;
//     background-color: #f8f8f8;
//     border-radius: 0;
//     border: 1px solid #e1e1e1;
//     margin: 0 5px;
// }

.message-a {
    background-color: rgba(22,184,248,.1);
}

.message-b{
    background-color: pink;
}

.message-c{
    background-color: #bbf4bb;
}
.message-d{
    background-color: #735bf7;
    color: #fff;
}
.message-e{
    background-color: #dc3545;
    color: #fff;
}
.message-f{
    background-color: #28a745;
    color: #fff;
}

h3.h3,h3#这是测试的h3效果,.ac h1 {
    display: block;
    font-weight: 300;
    background-image: linear-gradient(to right,#fff, #8BC34A, #4CAF50,#4CAF50,#4CAF50, #8BC34A, #FFF);
    background-color: #4CAF50;
    color: #FFF;
    font-size: 1.4em;
    line-height: 2em;
    margin: 14px 10px;
    padding: 10px 0;
    letter-spacing: 2px;
    text-shadow: 1px 1px 1px #666;
    text-align: center;
}
h4.h4,h4#这是测试的h4效果,.post h2 {
    display: block;
    font-weight: 200;
    background-image: linear-gradient(to right,#4CAF50, #8BC34A, #8BC34A, #FFF);
    background-color: #4CAF50;
    color: #FFF;
    font-size: 1.2em;
    line-height: 2em;
    margin: 36px 12px 10px 0;
    padding: 8px 10px 8px 15px;
    letter-spacing: 2px;
    border-left: solid #333 6px;
    text-shadow: 1px 1px 1px #666;
}


// .post>h1:not(:first-child)::before{
//     // display: block;
//     height: 5px;
//     content: "🐺";
//     margin-left: -1.3em;
//     font: normal 100%/1 Cochin, Georgia, "Times New Roman", serif;
//     color: #3CB371;
//     opacity: .8;
// }

.post>h1:not(.post-title){
    // padding-left: 50px;
    color: #505055;
    font-size: 26px;
}

.post>h3{
    margin-top: 40px;
    color: #8B2252;
}

// .post>h4::before{
//     display: block;
//     height: 2px;
//     content: "➽";
//     margin-left: -1.3em;
//     font: normal 150%/1 Cochin, Georgia, "Times New Roman", serif;
//     color: #FF8C00;
//     opacity: .9;
// }

.post>h4{
    font-size: 18px;
    //padding-left: 30px;
    color: #0378bb;
    margin-bottom: 22px;
}

.post>h5::before{
    display: block;
    height: 0px;
    content: "▶";
    // content: "🦉";
    margin-left: -1.4em;
    // font: normal 100%/1 Cochin, Georgia, "Times New Roman", serif;
    opacity: .9;
}
.post>h5{
    padding-left: 40px;
    color: #10b0a8;
}

.post>h6::before{
    display: block;
    height: 4px;
    content: "❦";
    margin-left: -1.0em;
    font: normal 150%/1 Cochin, Georgia, "Times New Roman", serif;
    color: #1E90FF;
}
.post>h6{
    color: #1E90FF;
    font-size: 15px;
    padding-left: 30px;
    padding-bottom: 8px;
    border-bottom: 2px solid #40B8FA;
}

// 以下是自定义的样式

.post>p>span{
    color: #3594f7;
}

.post>p>strong{
    color: #3CB371;
}

.post>div.double-box{
    border-width: 1px;
    border-style: solid;
    border-color: rgb(128, 177, 53);
    padding: 3px;
    display: inline-block;
	font-weight:800;
    margin-bottom: 1.2rem;
}

.post>div.double-box>span{
    border-width: 1px;
    border-style: dashed;
    border-color: rgb(128, 177, 53);
    padding: 0.2em 1em;
}

// 颜色方框的样式 2021-04
div.text-box{
    padding:12px;
    background-color:#4bacc6;
    color:#fff;
    margin:20px;
    font-size:28px;
    max-width:220px;text-align:center;font-family:'方正姚体';
    border:#fff solid 8px;
    border-radius:5px;
    box-shadow: 2px 4px 6px #666;
    display: inline-block;
}
div.t1 {
    background-color:#4bacc6;
}

div.t2 {
    background-color:#48d491;
}

div.t3 {
    background-color:#56e146;
}

div.t4 {
    background-color:#d5ec46;
}

div.t5 {
    background-color:#f79646;
}
